import React from 'react'
import { EventsStore } from 'stores/events'
import { formatDateToTimeZone } from 'utils'
import { usePageView, useStore } from 'hooks'
import { getServerSideWrapper } from 'utils'
import Head from 'components/Head'

import EventDetail from 'components/EventDetail'
import EventCard from 'components/EventCard'
import { THEME } from 'utils'

const EventDetailPage = ({ event }) => {
  useStore(EventsStore, { current: event })
  usePageView()

  return (
    <>
      <Head
        title={`${event.name} - Barstool Events`}
        description={event?.preview_description ?? `Join us for ${event.name}.`}
        ogImage={event.images.og}
      />
      <div className='external-event-wrapper'>
        <EventDetail event={event}>
          <div className='events-grid'>
            {event.events.map((_event, idx) => (
              <EventCard key={idx} event={_event} container={event} singleEvent={event?.events?.length === 1} />
            ))}
          </div>
        </EventDetail>
      </div>
      <style global jsx>{`
        .external-event-wrapper strong {
          color: ${event.theme?.accent?.color ?? '#000'};
        }
      `}</style>
      <style jsx>{`
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .events-grid {
            padding: 0;
            grid-gap: 15px;
            width: 100%;
            max-width: 1200px;
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(${event?.events?.length <= 3 ? event.events?.length : 3}, 1fr);
            justify-content: center;
          }
        }
      `}</style>
    </>
  )
}

export const getServerSideProps = getServerSideWrapper(async ({ params }) => {
  const { slug } = params
  const events = await EventsStore().find({ slug })
  if (!events || !events.length || events[0]?.status !== 'published') {
    return {
      error: {
        status: 404
      }
    }
  } else {
    return {
      event: events[0]
    }
  }
})

export default EventDetailPage
