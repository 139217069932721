import React, { useMemo } from 'react'
import classNames from 'classnames'
import { THEME } from 'utils'
import { DateTime } from 'luxon'

const EventCard = ({ event, container, singleEvent = false }) => {
  let linkProps = {}
  if ((event.status === 'live' || event.status === 'waitlist') && event.link) {
    linkProps = {
      href: event.link,
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  }
  function getButton() {
    let text
    switch (event.status) {
      case 'coming_soon':
        text = 'Coming Soon'
        break
      case 'live':
        text = 'Buy Tickets'
        break
      case 'waitlist':
        text = 'Join Waitlist'
        break
      default:
        text = 'Sold Out'
        break
    }
    return (
      <>
        <a className='btn' {...linkProps}>
          {text}
        </a>
        <style jsx>{`
          .btn,
          .btn:active,
          .btn:focus,
          .btn:hover {
            background-color: ${container.theme.cta.primaryColor || '#000'};
          }
          .btn {
            margin: auto 0 0;
            padding: 8px 0;
            font-size: 12px;
            width: 100%;
          }
          @media (min-width: 370px) {
            .btn {
              padding: 8px 0;
              font-size: 12px;
            }
          }
          @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
            .btn {
              padding: 6px 0;
              font-size: 12px;
            }

            @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
              .btn {
                padding: 10px 0;
                font-size: 14px;
              }
            }
          }
        `}</style>
      </>
    )
  }
  const startDateTime = useMemo(
    () => DateTime.fromISO(event.startDate, { zone: 'UTC' }).setZone(event.timezone || 'America/New_York'),
    [event]
  )
  return (
    <div className={classNames({ event: !singleEvent }, { singleEvent })}>
      <img src={event.image} alt={event.title} className='event-image' />
      <div className='event-info'>
        <div className='event-dates'>
          {startDateTime.toFormat('EEE')} &bull; {startDateTime.toFormat('MMM d')} &bull;{' '}
          {startDateTime.toFormat('h:mm a')} &bull; {startDateTime.toFormat('ZZZZ')}
        </div>
        <h2>{event.title}</h2>
        <div className='event-description' dangerouslySetInnerHTML={{ __html: event.description }}></div>
        {event?.link && getButton()}
      </div>
      <style jsx>{`
        .singleEvent {
          border: 1px solid #d2d2d2;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 800px;
          margin: 0 auto;
        }
        .singleEvent .event-description {
          font-size: 12px;
        }
        .event {
          border: 1px solid #d2d2d2;
          display: flex;
          flex-direction: column;
          background: #fff;
          margin: 10px 10px 20px 10px;
        }
        .event-image {
          max-width: 100%;
        }
        .event-info {
          padding: 8px;
          background: #fff;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
        .event-dates {
          color: #c62232;
          font-size: 9px;
          font-weight: 600;
          text-transform: uppercase;
        }
        h2 {
          color: #000;
          font-size: 12px;
          margin: 6px 0 2px;
          font-weight: 600;
        }
        .event-description {
          color: #6b6b6b;
          font-size: 10px;
          margin: 0 0 20px;
          line-height: 1.25;
          font-weight: 400;
        }
        p {
          color: #6b6b6b;
          font-size: 8px;
          line-height: 1;
          margin: 0 0 20px;
        }
        @media (min-width: 370px) {
          .event {
            border: 1px solid #d2d2d2;
            display: flex;
            flex-direction: column;
          }
          .event-image {
            max-width: 100%;
          }
          .event-info {
            padding: 10px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
          .event-dates {
            font-size: 10px;
          }
          h2 {
            font-size: 12px;
          }
          .event-description {
            font-size: 10px;
          }
          p {
            font-size: 8px;
            line-height: 1.3;
            margin: 2px 0 25px;
          }
        }
        @media (min-width: 400px) {
          .event-dates {
            font-size: 12px;
          }
          h2 {
            font-size: 14px;
          }
          .event-description {
            font-size: 12px;
          }
          p {
            font-size: 10px;
          }
          .singleEvent .event-description {
            font-size: 14px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .event {
            margin: 10px;
          }
          .event-info {
            padding: 12px;
          }
          h2 {
            font-size: 16px;
          }
          .event-description {
            font-size: 14px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .singleEvent {
            flex-direction: row;
            min-height: 265px;
          }
          .singleEvent .event-image {
            max-width: 335px;
            width: 100%;
            object-fit: cover;
          }
          .event {
            margin: 0 10px 10px;
          }
          .event-dates {
            font-size: 10px;
          }
          .event-info {
            padding: 10px;
          }
          h2 {
            font-size: 11px;
          }
          .singleEvent h2 {
            font-size: 16px;
          }
          .event-description {
            font-size: 8px;
          }
          p {
            font-size: 7px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .event {
            margin: 0 20px 20px;
          }
          .event-info {
            padding: 12px;
          }
          .event-dates {
            font-size: 12px;
          }
          h2 {
            font-size: 14px;
          }
          .event-description {
            font-size: 10px;
          }
          p {
            font-size: 9px;
          }
          .singleEvent .event-description {
            font-size: 14px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .event-info {
            padding: 14px;
          }
          h2 {
            font-size: 16px;
          }
          .event-description {
            font-size: 12px;
          }
          p {
            font-size: 10px;
          }
          .singleEvent .event-description {
            font-size: 14px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .event-info {
            padding: 16px;
          }
          .event-dates {
            font-size: 14px;
          }
          h2 {
            font-size: 18px;
            margin: 8px 0 2px;
          }
          .event-description {
            font-size: 14px;
          }
          p {
            font-size: 11px;
          }
          .singleEvent .event-description {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  )
}

export default EventCard
